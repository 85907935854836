<template lang="pug">
.app-footer
  stimul-reg-banner.mb-6(v-if="showStimulBanner")

  nav.app-footer__nav.footer-nav.mb-0
    router-link.footer-nav__item(:to="$path.catalog()") Активы
    router-link.footer-nav__item(:to="$path.services()") Сервисы
    router-link.footer-nav__item(:to="$path.about()") О&nbsp;проекте

  nav.app-footer__nav.footer-nav
    router-link.footer-nav__item(:to="$path.blog()") Блог
    router-link.footer-nav__item(:to="$path.faq()") Помощь

  get-apps.mb-10

  .t-caption-xsmall.c-black.mb-2 Консультация
  .footer-contacts.mb-8
    a.footer-contacts__item.footer-contacts__item--phone.mb-2(href="tel:+74955479894") 8 (495) 547-98-94
    a.footer-contacts__item.footer-contacts__item--email(href="mailto:portal_da@sberbank.ru") portal_da@sberbank.ru

  nav.footer-links
    .t-caption-xsmall.c-gray80.pl-2.pr-6.mb-3
      span Находясь на сайте, вы соглашаетесь с
      a.c-gray80.ml-1(
        href=`${process.env.VUE_APP_API_HOST}/documents/agreement.pdf`
        rel="nofollow"
        target="_blank"
      ) Пользовательским соглашением
      span ,
      a.c-gray80.ml-1(
        href=`${process.env.VUE_APP_API_HOST}/documents/politics.pdf`
        rel="nofollow"
        target="_blank"
      ) Политикой конфиденциальности
      span.ml-1 и
      a.c-gray80.ml-1(href="https://www.sberbank.ru/ru/pol_pd" rel="nofollow" target="_blank") Политикой обработки персональных данных
      span . На сайте применяются
      a.c-gray80.ml-1(
        href=`${process.env.VUE_APP_API_HOST}/documents/recommendation_system.pdf`
        rel="nofollow"
        target="_blank"
      ) Рекомендательные технологии
      span .
    .t-caption-xsmall.c-text.mb-4.px-2
      span © 2019–{{ new Date().getFullYear() }} Portal DA – Маркетплейс бизнес&#8209;активов и&nbsp;залогового&nbsp;имущества, созданный
      a.t-caption-xsmall.c-gray80.ml-1(
        href="https://www.sberbank.ru/"
        rel="nofollow"
        target="_blank"
      ) ПАО Сбербанк
      span .

    .footer-links__item.ui-clickable(@click="onDesktopClick") Версия для ПК
</template>

<script>
  import GetApps from '@/components/app/GetApps.vue';
  import StimulRegBanner from '@/components/stimul-reg/StimulRegBanner.vue';

  export default {
    name: 'app-footer',
    components: { GetApps, StimulRegBanner },
    inject: ['openDesktop'],

    props: {
      noBanner: { type: Boolean, default: false },
    },

    computed: {
      showStimulBanner() {
        return !this.$store.getters['currentUser'] && !this.noBanner;
      },
    },

    methods: {
      onDesktopClick() {
        this.openDesktop({
          path: this.$route.path,
          query: this.$route.query,
          text: 'Открыть эту страницу в полной версии сайта?',
        });
      },
    },
  };
</script>

<style lang="scss">
  .app-footer {
    margin: 0 16px;
    padding: 24px 0;
  }

  .footer-nav {
    @include text-style('normal-bold', 'light');
    display: flex;
    margin-bottom: 32px;

    .footer-nav__item {
      text-decoration: none;
      padding: 8px;
      margin-right: 4px;
      color: c('black', 'light') !important;

      &:first-child {
        margin-left: -8px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .footer-contacts {
    display: flex;
    flex-direction: column;

    .footer-contacts__item {
      @include font-size(15, 18, 500);
      margin-bottom: 8px;
      padding-bottom: 3px;
      text-decoration: none !important;
      color: c('black', 'light') !important;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .footer-contacts__item--phone {
    }

    .footer-contacts__item--email {
      // border-bottom: 1px solid c('black10', 'light');
    }
  }

  .footer-links {
    margin-left: -8px;

    .footer-links__item {
      @include font-size(12, 14);
      display: block;
      padding: 8px;
      color: c('black80', 'light') !important;
      cursor: pointer;
      text-decoration: underline;
    }
  }
</style>
