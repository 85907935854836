<template lang="pug">
app-layout
  .page-profile.pa-6.pb-8
    .t-h1-mobile.mb-6 Личный кабинет
    .d-flex.align-center.mb-8
      .avatar(:class="proUser ? 'pro-label' : 'basic-label'")
        ui-icon.c-black40.mr-4(name="user-round" size=64)
        .label
      .t-caption-normal {{ currentUser.name }}

    .plashka.mb-4(v-if="!proUser")
      .t-h3-mobile.mb-4 Получите статус PRO
      p.t-middle.c-black80.mb-4 Добавьте паспортные данные, ИНН и СНИЛС для получения доступа к расширенному функционалу:
      ul.t-middle.mb-8.pl-0
        li.mb-2.d-flex.align-center
          ui-icon.mr-3.c-primary(name="check2")
          .c-black80 Участие в аукционах
        li.mb-2.d-flex.align-center
          ui-icon.mr-3.c-primary(name="check2")
          .c-black80 Размещение актива на продажу
        li.d-flex.align-center
          ui-icon.mr-3.c-primary(name="check2")
          .c-black80 Доступ к закрытой части

      ui-btn.mb-2(
        large
        primary
        :to="$path.createPRO()"
        width="100%"
      ) Получить статус PRO

    .plashka
      .t-middle.mb-3 Полный функционал личного кабинета доступен в версии для ПК.
      ui-btn.mb-3(
        large
        secondary
        width="100%"
        @click="onDesktopClick"
      ) Открыть версию для ПК
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';
  import AppNavDrawer from '@/components/app/AppNavDrawer';
  import AppHeader from '@/components/app/AppHeader';
  import AppFooter from '@/components/app/AppFooter';

  import { UiIcon, UiBtn } from '@/uikit';
  import { mapGetters } from 'vuex';

  export default {
    name: 'page-splash',
    components: { AppLayout, AppNavDrawer, AppHeader, AppFooter, UiBtn, UiIcon },
    inject: ['openDesktop'],

    data: () => ({
      navDrawer: false,
    }),

    computed: {
      proUser() {
        return this.currentUser ? this.currentUser.status === 'pro' : false;
      },

      ...mapGetters(['currentUser']),
    },

    methods: {
      onDesktopClick() {
        this.openDesktop({ path: this.$route.path, query: this.$route.query });
      },
    },
  };
</script>

<style lang="scss">
  .page-profile {
    .plashka {
      background: white;
      border-radius: 4px;
      padding: 24px;
    }
    .avatar {
      position: relative;
    }
    .avatar::before {
      position: absolute;
      bottom: -3px;
      left: 32px;
      transform: translateX(-50%);
      color: white;
      padding: 4px 8px;
      border-radius: 12px;
      font-size: 14px;
      line-height: 16;
      font-weight: 500;
      height: 26px;
      display: flex;
      align-items: center;
      z-index: 1;
    }
    .pro-label:before {
      content: 'PRO';
      background: c('primary40', 'light');
    }
    .basic-label:before {
      content: 'Basic';
      background: c('black60', 'light');
    }
  }
</style>
