<template lang="pug">
.stimul-reg-banner
  .t-normal.mb-3 Зарегистрируйтесь или войдите, чтобы открыть все возможности Portal DA!
  ui-btn(secondary :to="$path.signIn()")
    ui-icon.c-primary(name="enter" left)
    | Вход и регистрация
</template>

<script>
  import { UiIcon, UiBtn } from '@/uikit';
  export default {
    name: 'stimul-reg-banner',
    components: { UiIcon, UiBtn },
  };
</script>

<style lang="scss">
  .stimul-reg-banner {
    padding: 24px 45px 24px 90px;
    box-shadow: 0 0 10px rgb(0 0 0 / 6%);
    border-radius: 4px;
    background: url('~@/assets/message-approved.svg') no-repeat white;
    background-size: 80px;

    .v-btn .v-btn__content {
      font-weight: 500;
    }
  }
</style>
